import React, { useContext, useEffect, useState } from "react";

import OverlayInput from "../components/OverlayInput";
import LocationIcon from "../../../../assets/icons/LocationIcon";
import MicrophoneIcon from "../../../../assets/icons/MicrophoneIcon";
import SendButton from "../components/SendButton";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import OverlayInputs from "../components/OverlayInputs";
import { VoiceContext } from "../components/Voice";
import useReadUserLocation from "../../../../customHooks/useReadUserLocation";
import { useAudioRecorder } from "react-audio-voice-recorder";
import Map from "../../../../kit/Map/Map";
import { fetchLocationName } from "../../../../kit/Map/MapUtils";
import useStopBodyScroll from "../../../../customHooks/useStopBodyScroll";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import OverlayCard from "../components/OverlayCard";
import ReturnArrow from "../../../Common/Layout/ReturnArrow/ReturnArrow";
import {
  EMERGENCY_THEMES,
  EmergencyOverlaysContext,
} from "../EmergencyOverlaysContext";
import { needHelpIconV2 } from "../../../Auth/landingPage/images";
import MuiButton from "../../../../kit/Button/MuiButton";
import useSound from "../../../../customHooks/useSound";
import completeSound from "../../../../assets/sounds/complete.mp3";
import { HiChevronLeft } from "react-icons/hi2";

import { DoubleBorder } from "../../../Common/Styled";
const Modal = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: "20px",
    background: "#fafffa",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    padding: 10,
    minWidth: "359px",
    overflowX: "hidden",
    border: "3px solid #0270A5",
    position: "relative",
  },
}));

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.6
  ); /* Grayed-out background color with some transparency */
  z-index: 99;
`;

const Title = styled.div`
  display: flex;
  gap: 15px;

  align-items: center;
  color: ${(props) => props.color ?? "var(--preview-txt, #105F84)"};
  font-family: "NotoSans-600";
  font-size: ${(props) => props.fontSize ?? "24px"};
  padding: 10px 0 15px 0;
`;
const ClickableSpan = styled.span`
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
  cursor: pointer;
  text-decoration: ${(props) => (props.isBold ? "underline" : "none")};
`;
const CardsWrapper = styled.form`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  margin-top: 12px;
  width: 100%;
`;

const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  width: 103%;

  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center;
`;

const CategoryButton = styled.button`
  background: ${({ isActive }) => (isActive ? "#316788" : "#e5eeff")};
  border-radius: 10px;
  padding: 5px 12px;
  font-family: "NotoSans-600";
  font-size: 18px;
  color: ${({ isActive }) => (isActive ? "#e5eeff" : "#316788")};
  cursor: pointer;

  &:focus {
    background: #316788;
    color: white;
    cursor: pointer;
  }
`;

// const CardsWrapper = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   margin: 0;
//   margin-top: 12px;
//   width: 100%;
// `;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  border: 2px solid white;
  gap: 15px;
  margin-bottom: 10px;
`;
const Cancel = styled.div`
  text-align: center;
  cursor: pointer;
  color: #105f84;
  font-family: "NotoSans-600";
  font-size: 18px;
  margin-right: 20px;
  background: #e4fae6;
  border-radius: 10px;
  padding: 5px 15px;
  box-shadow: 5px 2px 5px 0px #00000040;
`;
const InformationContainer = styled.div`
  margin: auto;
  padding: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
`;
const Information = styled.p`
  margin: 0;
  color: var(--preview-txt, #58656d);
  text-align: center;
  font-family: "NotoSans-600";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const Text = styled.p`
  margin: 0;
  color: #ffffff;
  font-family: "NotoSans-600";
  font-size: 19.09px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const ArrowWrapper = styled.div`
  cursor: pointer;
  top: 20px;
  left: 10px;
  position: absolute;
`;

const categoriesButtons = [
  "כבאות והצלה ",
  "רפואה",
  "עזרה נפשית",
  "מצב חירום בבית",
  "סיוע לוגיסטי",
];

const NeedHelpOverlay = ({ overlay, setOverlay, isReadyToSend, action }) => {
  const navigate = useNavigate();

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: { fullName: " " },
  });

  const [recordingDuration, setRecordingDuration] = useState(0);
  const [boldText, setBoldText] = useState("הוספת פרטים");
  const [showMap, setShowMap] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [searchUserLocation, setSearchUserLocation] = useState();
  const [activeButton, setActiveButton] = useState(null);
  const [currentCategory, setCurrentCategory] = useState("רפואה");
  const [phoneNumber, setPhoneNumber] = useState();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [playSound] = useSound(completeSound);
  const {
    stopRecording,
    recordingBlob,
    isRecording,
    recordingTime,
    startRecording,
  } = useAudioRecorder();
  const handlePartClick = (part) => {
    setBoldText(part);
  };
  const { listening, StopRecord } = useContext(VoiceContext);
  const handleCloseClick = () => {
    if (showMap) {
      setShowMap(false);
      return;
    }
    setOverlay(null);
    action("");
    if (isRecording) {
      stopRecording();
    }
    if (listening) {
      StopRecord?.();
    }
  };

  useEffect(() => {
    const spans = document.querySelectorAll(".clickable-span");

    const handleSpanClick = (event) => {
      spans.forEach((span) => {
        span.classList.remove("active");
      });
      event.currentTarget.classList.add("active");
    };

    spans.forEach((span) => {
      span.addEventListener("click", handleSpanClick);
    });

    // Set the first span to be active by default
    if (spans.length > 0) {
      spans[1].classList.add("active");
    }

    return () => {
      spans.forEach((span) => {
        span.removeEventListener("click", handleSpanClick);
      });
    };
  }, []);
  const { userLocation } = useReadUserLocation();
  const [locationBgColor, setLocationBgColor] = useState();
  const { setShowOverlay, setTheme } = useContext(EmergencyOverlaysContext);
  const handleFormSubmit = async (data) => {
    if (listening) {
      StopRecord?.();
    }
    playSound();
    await action({
      ...data,
      recording: {
        blob:
          recordingDuration && recordingDuration != "0"
            ? recordingBlob
            : undefined,
        duration: recordingDuration,
      },
      selectedLocation: { loc: searchUserLocation, name: locationName },
      phone: phoneNumber,
    });
    setTheme(EMERGENCY_THEMES.need_help);
    setTimeout(() => {
      setShowOverlay(false);
    }, 200);
  };
  const endRecord = () => {
    setRecordingDuration(recordingTime);
    stopRecording();
  };

  useEffect(() => {
    if (recordingTime >= 30) {
      endRecord();
    }
  }, [recordingTime]);

  useStopBodyScroll(overlay);
  useEffect(() => {
    if (!userLocation) {
      return;
    }
    setSearchUserLocation(userLocation);
    fetchLocationName(userLocation).then((name) => {
      setLocationName(name);
    });
  }, [userLocation]);

  const micOnClick = () => {
    if (isRecording) {
      endRecord();
      return;
    }
    startRecording();
  };

  const renderMessageInput = () => (
    <OverlayInput
      register={register}
      setValue={setValue}
      isReadyToSend={isReadyToSend}
      inputHeight={"80px"}
      showMicrophone={true}
      isVoiceRecording={isRecording}
      hasVoiceRecording={recordingBlob}
      usePlainDesign
      useSpeakIcon
      micBottomVal={"30px"}
      focusOnLoad
      micBackgroundColor={"#FFF"}
      micBorder={"1px solid #EF7300"}
    />
  );

  const renderTopButtons = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        width: "98%",
        margin: "20px auto 10PX auto",
      }}
    >
      <OverlayCard
        showShakeAnimation
        Icon={MicrophoneIcon}
        iconWrapperBgColor={"#DAF3DCB2"}
        iconWrapperBorder={"1px solid #EF7300"}
        iconWrapperHeight={"43px"}
        titleFontSize={"20px"}
        titleFontWeight={"500"}
        iconSize={"25px"}
        IconColor={isReadyToSend ? "#7FB383" : undefined}
        iconText={"Record"}
        counter={
          isRecording
            ? recordingTime
            : recordingDuration && recordingDuration !== 0
            ? recordingDuration
            : undefined
        }
        showDelete={!!(recordingDuration && recordingDuration !== 0)}
        onDeleteClick={() => {
          setRecordingDuration(0);
          stopRecording();
        }}
        title={"הקליטו הודעתכם"}
        isVoiceComponent
        isReadyToSend={isReadyToSend}
        wrapperBgColor={
          recordingTime || (recordingDuration && recordingDuration !== 0)
            ? "#EBDBDD"
            : "#38A1BC1A"
        }
        onClick={micOnClick}
        height={"51px"}
      />
      <OverlayCard
        showShakeAnimation
        Icon={LocationIcon}
        iconWrapperBgColor={"#DAF3DCB2"}
        iconWrapperBorder={"1px solid #EF7300"}
        iconWrapperHeight={"43px"}
        titleFontSize={"20px"}
        titleFontWeight={"500"}
        iconSize={"30px"}
        IconColor={"#446D47"}
        title={"Location הוספת מיקום"}
        isReadyToSend={isReadyToSend}
        wrapperBgColor={
          locationBgColor ? "rgba(218, 243, 220, 1)" : "#38A1BC1A"
        }
        onClick={() => {
          setShowMap(true);
          setLocationBgColor("sel");
        }}
        height={"51px"}
      />
    </div>
  );

  const renderContent = () => (
    <>
      <ArrowWrapper onClick={handleCloseClick}>
        <HiChevronLeft color={"#105F84"} fontSize={30} />
      </ArrowWrapper>
      <Title fontSize={overlay?.titleFontSize} color={overlay?.titleColor}>
        <img
          style={{
            width: "41px",
            height: "41px",
          }}
          src={needHelpIconV2}
          alt="helpNeededIcon"
        />
        {"בקשת סיוע מהקהילה"}
      </Title>
      <CategoriesContainer>
        {categoriesButtons.map((c) => (
          <CategoryButton
            isActive={currentCategory === c}
            onClick={() => setCurrentCategory(c)}
          >
            {c}
          </CategoryButton>
        ))}
      </CategoriesContainer>
      {renderMessageInput({
        isOrganization: false,
        isEmergencyOverlay: false,
      })}
      {renderTopButtons()}
      <ButtonWrapper>
        <DoubleBorder borderColor={"#0270A5"}>
          <SendButton
            color={"#FFF"}
            backgroundColor={"#0270A5"}
            border={"0"}
            padding={"25px 5px"}
            borderRadius={"5px"}
            width={"100%"}
            marginTop={0}
            isReadyToSend={isReadyToSend}
            onSubmit={handleSubmit(handleFormSubmit)}
            reverseOrder
            text={
              <>
                <Text>שליחת הודעה</Text>
                {"\u00A0 \u00A0 \u00A0 \u00A0 \u00A0"}
                <Text>Send</Text>
              </>
            }
          />
        </DoubleBorder>
        <MuiButton
          sx={{
            color: "#105F84",
            fontSize: 18,
            fontFamily: "NotoSans-600",
            border: 0,
            borderRadius: "10px",
            width: 118,
            height: 30,
            backgroundColor: "#EDF9ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow: "5px 2px 5px 0px rgba(0, 0, 0, 0.25)",
            "&:hover": {
              border: 0,
            },
          }}
          onClick={handleCloseClick}
        >
          <div>Cancel</div>
          <div>בטל</div>
        </MuiButton>
      </ButtonWrapper>
      {
        <Title fontSize={"19px"} color={overlay?.titleColor}>
          <ClickableSpan
            className="clickable-span"
            isBold={boldText === "איש קשר"}
            onClick={() => setBoldText("איש קשר")}
          >
            איש קשר
          </ClickableSpan>
          /
          <ClickableSpan
            className="clickable-span"
            isBold={boldText === "הוספת פרטים"}
            onClick={() => setBoldText("הוספת פרטים")}
          >
            הוספת פרטים
          </ClickableSpan>
        </Title>
      }
      <CardsWrapper>
        <OverlayInputs
          phoneNumber={phoneNumber}
          title={"פרטים אישיים"}
          hasSubTitle={true}
          register={register}
          setPhoneNumber={setPhoneNumber}
          fullName={watch("fullName")}
          useSeparateStyle
        />
      </CardsWrapper>
    </>
  );

  return (
    <>
      <BackgroundOverlay aria-hidden onClick={handleCloseClick} />
      <Modal onClose={handleCloseClick} open>
        {showMap ? (
          <>
            <ReturnArrow
              zIndex={"9999"}
              forceShowArrow
              onClickOverride={() => setShowMap(false)}
            />
            <div style={{ width: "100%", marginTop: "35px" }}>
              <Map
                disableClick={false}
                height={"60vh"}
                initialSelectedLocation={userLocation ?? searchUserLocation}
                showSearch={true}
                selectedLocationName={locationName}
                onLocationSelect={(loc) => {
                  setSearchUserLocation(loc);
                  fetchLocationName(loc).then((name) => {
                    setLocationName(name);
                  });
                }}
              />
            </div>
            <InformationContainer>
              <Information>{locationName}</Information>
            </InformationContainer>
          </>
        ) : (
          renderContent()
        )}
      </Modal>
    </>
  );
};

export default NeedHelpOverlay;
