import {
  MainEmergencyZone,
  MainOrgZone,
} from "../../customHooks/useFetchEmergencyEvents";
import {
  ImaotRamatAvivEvents,
  ImaotYadElihaoEvents,
  TaskZone,
} from "../../customHooks/useFetchTasks";
export const AREAS = [
  {
    enname: "Tel Aviv",
    name: "תל אביב",
    value: TaskZone.TelAvivCity,
  },
  {
    enname: "Gderot",
    name: "ישובי גדרות",
    value: TaskZone.GderotCity,
  },
  {
    enname: "ort student",
    name: "אורט - תל אביב",
    value: TaskZone.OrtSchool,
  },
  {
    name: "תל אביב",
    value: MainEmergencyZone.Emergency,
  },
  {
    name: "אחים לנשק",
    value: MainEmergencyZone.BrothersInArms,
  },
  {
    name: "כוח קפלן",
    value: MainEmergencyZone.KaplanForce,
  },
  {
    enname: "taglit",
    name: "תגלית",
    value: MainOrgZone.Taglit,
  },
  {
    name: "איחוד והצלה",
    value: MainOrgZone.UnitedRescue,
  },
  {
    name: "הסוכנות היהודית",
    value: MainOrgZone.JewishAgency,
  },
  {
    name: "קק״ל",
    value: MainOrgZone.KKL,
  },
  {
    name: "חקלאות כחול לבן",
    value: MainOrgZone.BlueWhite,
  },
];

export const TEL_AVIV_COMUNITIES = {
  name: TaskZone.TelAvivCity,
  comunities: [
    {
      // אמהות חזקות - רמת אביב
      name: "אמהות רמת אביב",
      value: TaskZone.ImaotRamatAviv,
      activites: TaskZone.ImaotRamatAviv,
      events: ImaotRamatAvivEvents.ImaotRamatAviv1,
      tasks: TaskZone.ImaotRamatAviv,
    },
    {
      // אמהות  - שכונת יד אליהו
      name: "אמהות יד אליהו",
      value: TaskZone.ImaotYadElihao,
      activites: TaskZone.ImaotYadElihao,
      events: ImaotYadElihaoEvents.ImaotYadElihao1,
      tasks: TaskZone.ImaotYadElihao,
    },
    {
      name: "נגה",
      value: TaskZone.NogaCity,
      activites: TaskZone.NogaCity,
      events: TaskZone.NogaCity,
      tasks: TaskZone.NogaCity,
    },
  ],
};
