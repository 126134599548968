import styled from "styled-components";
import { useMemo, useState, useContext, useEffect } from "react";
import Header from "../../../Common/Layout/Header/Header";
import SearchForm from "../../../../kit/SearchForm/SearchForm";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import { TogetherIcon } from "../../../../assets/icons/Logo";
import { useNavigate, useParams } from "react-router-dom";
import { TaskZone } from "../../../../customHooks/useFetchTasks";
import Map from "../../../../kit/Map/Map";
import { getPhotoSrc } from "../../Post/utils";
import Button from "../../../../kit/Button/Button";
import { ZoneRouteContext } from "../../../../store/zoneRouteContext";
import useCurrentUser from "../../../../customHooks/useCurrentUser";
import { EMERGENCY_TABS } from "./constants";
import { getLogoByZone } from "../../../Common/utils/logo";
import { useForm } from "react-hook-form";
import { TEL_AVIV_COMUNITIES } from "../../../Search/constants";
import useFetchEcosystemById from "../../../../customHooks/useFetchEcosystemById";
import { EcosystemZone } from "../../../Admin/AdminArea/CreatePage/constans";
import useMediaQueryConstants from "../../../../customHooks/useMediaQueryConstants";
import {
  LOGIN_ROUTES,
  MAIN_ROUTES,
} from "../../../Common/Layout/Main/constants";
import { ZONES } from "../../Zone/Common/CardDetailsPage/constants";
import useMapEvents from "./hooks/useMapEvents";
import { EmergencyOverlaysContext } from "../../../Emergency/Contacts/EmergencyOverlaysContext";
const Wrapper = styled.div`
  position: relative;
`;
const Title = styled.div`
  color: #003f5d;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  letter-spacing: 0.18px;
`;
const Container = styled.form`
  margin: ${(margin) => margin ?? "20px 9px"};
`;
const DropDownsContainer = styled.div`
  display: flex;
  justify-content: ${({ isWideScreen, isMediumScreen }) =>
    isWideScreen || isMediumScreen ? "center" : "space-between"};
  width: 100%;
  gap: 5px;
  margin-bottom: 17px;
  position: relative;
  height: 35px;
`;
const DropDownWrapper = styled.div`
  width: ${(props) => props.width ?? "50%"};
  position: ${({ position }) => position ?? "static"};
  top: ${({ top }) => top ?? "auto"};
  bottom: ${({ bottom }) => bottom ?? "auto"};
  left: ${({ left }) => left ?? "auto"};
  right: ${({ right }) => right ?? "auto"};
  height: 100%;
`;
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;
const TabsContainer = styled.div`
  margin: 10px auto;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: ${({ marginTop }) => marginTop ?? "0"};
`;
const Circle = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;
const Tab = styled.button`
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  width: 110px;
  height: 29.921px;
  border-radius: 17.861px;
  border: ${(props) =>
    props.backgroundColor
      ? `1px solid ${props.backgroundColor}`
      : "1px solid #4b778d;"};
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fafffa"};
  color: ${(props) =>
    props.color ? props.color : "var(--preview-txt, #58656d)"};
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.216px;
  cursor: pointer;
  @media (max-width: 380px) {
    font-size: 12px;
  }
  @media (max-width: 350px) {
    font-size: 10px;
  }
`;
const MapContainer = styled.div`
  position: relative;
  margin: auto;
  margin-top: 30px;
  padding: 0 6px;
`;
const SearchFormStyle = {
  height: "38px",
  width: "100%",
  borderRadius: "20px",
  borderColor: "#C9D7E0",
};
const EventInformationContainer = styled.div`
  width: 213px;
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  background-color: white;
  bottom: 31px;
  right: 26px;
  border-radius: 12.845px;
  background: #fff;
  box-shadow: 0px 2.23395px 15.63762px 0px rgba(0, 0, 0, 0.15);
`;
const EventTitle = styled.h3`
  margin: 0;
  color: var(--titles, #58656d);
  font-family: Assistant;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  letter-spacing: -0.27px;
  display: flex;
  justify-content: space-between;
`;
const EventImage = styled.img``;
const EventDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: #58656d;
  font-family: Assistant;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
`;
const VisitBtn = styled.button`
  width: 98px;
  height: 30px;
  padding: 8px 12.5px;
  justify-content: center;
  align-items: center;
  color: var(--white, #fff);
  text-align: center;
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.404px;
  border-radius: 21.222px;
  background: var(--donor-dark-green, #1c6662);
  border: none;
  display: block;
  margin: auto;
  cursor: pointer;
  text-decoration: none;
`;
const CloseIconWrapper = styled.div`
  cursor: pointer;
`;
const EmergencyLoginButtonStyles = {
  color: "#267C97",
  fontSize: "20px",
  backgroundColor: "transparent",
  border: "2px solid #267C97",
  borderRadius: "16.075px",
  height: "38px",
  width: "20%",
};
const EmergencyAdminButtonStyles = {
  color: "#ffffff",
  fontSize: "20px",
  backgroundColor: "#267C97",
  border: "2px solid #267C97",
  borderRadius: "16.075px",
  height: "38px",
  width: "20%",
};

export const Tabs = {
  Communities: "communities",
  Tasks: "tasks",
  Events: "events",
  ActivitiesEvents: "activitiesEvents",
  Emergency: "emergency",
  All: "all",
};

const EMERGENCY_ZONE = "emergency";

const DateFormatComponent = ({ startDate, endDate }) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    return "";
  }

  const startDay = start.getDate();
  const endDay = end.getDate();
  const startMonth = start.getMonth() + 1;
  const endMonth = end.getMonth() + 1;
  const startYear = start.getFullYear();
  const endYear = end.getFullYear();

  let formattedDate = "";

  if (startYear !== endYear) {
    formattedDate = `${startDay}/${startMonth}/${startYear}-${endDay}/${endMonth}/${endYear}`;
  } else if (startMonth !== endMonth) {
    formattedDate = `${startDay}/${startMonth}-${endDay}/${endMonth}/${startYear}`;
  } else if (startDay !== endDay) {
    formattedDate = `${startDay}-${endDay}/${startMonth}/${startYear}`;
  } else {
    formattedDate = `${startDay}/${startMonth}/${startYear}`;
  }

  return <div>{formattedDate}</div>;
};

function ZoneMap(props) {
  const { setLoginRoute } = useContext(ZoneRouteContext);
  const { isAuthenticated } = useCurrentUser();
  const { setShowOverlay, setOverlayProps } = useContext(
    EmergencyOverlaysContext
  );
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const [currentCommunities, setCurrentCommunities] = useState([]);
  const navigate = useNavigate();
  const { zone } = useParams();
  const { handleSubmit, setValue, watch } = useForm();
  const area = watch("area");
  const getCommunities = () => {
    if (area === TaskZone.TelAvivCity) {
      setCurrentCommunities(TEL_AVIV_COMUNITIES?.comunities);
    } else return setCurrentCommunities([]);
  };
  const type = "all";
  const [activeTab, setActiveTab] = useState(
    zone?.toLowerCase?.() === EMERGENCY_ZONE ? Tabs.Emergency : Tabs.All
  );
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { ecosystem } = useFetchEcosystemById(zone);
  const isEnglishEcosystem = useMemo(
    () =>
      ecosystem?.zoneType === EcosystemZone.USA ||
      ecosystem?.zoneType === EcosystemZone.Europe,
    [ecosystem]
  );

  const mapEvents = useMapEvents({
    zone,
    activeTab,
    setSelectedEvent,
    area,
    isEnglishEcosystem,
    Tabs,
  });

  const onSubmit = async (payload) => {
    console.log(payload, zone);
  };
  useEffect(() => {
    getCommunities();
    setValue("community", null);
  }, [area]);

  useEffect(() => {
    if (type !== "all") {
      setValue("area", zone);
    }
  }, []);
  return (
    <Wrapper>
      <Header
        titlesLang="heb"
        Icon={
          getLogoByZone(zone) ??
          (() => <TogetherIcon width={240} height={60} />)
        }
        hasPersonIcon={false}
        hasEarth={false}
        showLogoutIcon={false}
        showNavbar={false}
        title={ecosystem?.title}
      />
      {zone === ZONES.VOLUNTEERING_PLEASURE && (
        <Title>מתנדבים ומבלים בסביבתכם</Title>
      )}
      <Container onSubmit={handleSubmit(onSubmit)}>
        {/* <DropDownsContainer
          isWideScreen={isWideScreen}
          isMediumScreen={isMediumScreen} */}
        {/* > */}
        {/* <DropDownWrapper
            width={isWideScreen || isMediumScreen ? "244px" : "50%"}
          >
            <ButtonWithDropDown
              activeLink={"אירגון / קהילה"}
              links={currentCommunities ?? []}
              color={"#4B778D"}
              activeLinkColor={"#7FB383"}
              fontSizeActiveLink="16px"
              border="1.377px solid #7FB383"
              iconColor="#7FB383"
              textAlign="left"
              LiItemTextAlign="right"
              height="36px"
              borderRadius={"30px"}
              searchFormheight={"30px"}
              searchFormFontSize={"15px"}
              iconHeight={"20px"}
              iconWidth={"20px"}
              language="heb"
              backgroundColor={"#fff"}
              fontWeightActiveLink={600}
              activeLinkMargin={"0 0 10px 0"}
              showUnderline={false}
              isRoutes={false}
              isReversed={true}
              maxListHeight="213px"
              fullWidth
              setState={(value) => {
                setValue("community", value);
              }}
            />
          </DropDownWrapper>
          <DropDownWrapper
            width={isWideScreen || isMediumScreen ? "244px" : "48%"}
          >
            <ButtonWithDropDown
              activeLink={"כל האזורים"}
              links={AREAS}
              color={"#4B778D"}
              activeLinkColor={"#7FB383"}
              fontSizeActiveLink="16px"
              border="1.377px solid #7FB383"
              iconColor="#7FB383"
              textAlign="left"
              LiItemTextAlign="right"
              height="36px"
              borderRadius={"30px"}
              searchFormheight={"30px"}
              searchFormFontSize={"15px"}
              iconHeight={"20px"}
              iconWidth={"20px"}
              language="heb"
              backgroundColor={"#fff"}
              fontWeightActiveLink={600}
              activeLinkMargin={"0 0 10px 0;"}
              showUnderline={false}
              isRoutes={false}
              isReversed={true}
              maxListHeight="213px"
              fullWidth
              setState={(value) => {
                setValue("area", value);
                setValue("community", null);
              }}
            />
          </DropDownWrapper> */}
        {/* </DropDownsContainer> */}
        <SearchWrapper>
          <SearchForm
            style={SearchFormStyle}
            iconWidth="20"
            iconHeight="20"
            lang="heb"
            iconColor="#6C899B"
          />
          {!isAuthenticated && (
            <Button
              styles={EmergencyLoginButtonStyles}
              text={"Team"}
              action={() => {
                setLoginRoute("/activities/map/emergency");
                navigate(`/${MAIN_ROUTES.LOGIN}/${LOGIN_ROUTES.EMERGENCY}`);
              }}
            />
          )}
          {zone?.toLowerCase?.() === EMERGENCY_ZONE && (
            <Button
              styles={EmergencyAdminButtonStyles}
              text={"חמ״ל"}
              goToLink={"/admin/emergency"}
            />
          )}
        </SearchWrapper>
        <TabsContainer>
          {/*TODO: Active tabs  */}
          {zone?.toLowerCase?.() === EMERGENCY_ZONE &&
            EMERGENCY_TABS.map((tab) => (
              <Tab
                onClick={() => {
                  setActiveTab(tab.tabName);
                }}
                style={{
                  fontSize: activeTab === tab.tabName && "20px",
                  width: activeTab === tab.tabName && "120px",
                  height: activeTab === tab.tabName && "35px",
                }}
                backgroundColor={tab.backgroundColor}
                color={tab.color}
              >
                {tab.title}
                {tab.icon ? tab.icon : <Circle color={tab.iconColor} />}
              </Tab>
            ))}
        </TabsContainer>
        <TabsContainer>
          <Tab
            onClick={() => {
              setActiveTab(Tabs.Communities);
            }}
            style={{
              background:
                activeTab === Tabs.Communities && "rgba(39, 198, 169, 0.50)",
            }}
          >
            {isEnglishEcosystem ? "Communities" : "אירועים"}
            <Circle color={"#0FACDE"} />
          </Tab>
          <Tab
            onClick={() => {
              setActiveTab(Tabs.ActivitiesEvents);
            }}
            style={{
              background:
                activeTab === Tabs.ActivitiesEvents &&
                "rgba(39, 198, 169, 0.50)",
            }}
          >
            {isEnglishEcosystem ? "Activities" : "פעילויות"}
            <Circle color={"#27C6A9"} />
          </Tab>
          <Tab
            onClick={() => {
              if (isEnglishEcosystem) {
                setActiveTab(Tabs.Events);
                return;
              }
              setActiveTab(Tabs.Tasks);
            }}
            style={{
              background:
                ((activeTab === Tabs.Tasks && !isEnglishEcosystem) ||
                  (activeTab === Tabs.Events && isEnglishEcosystem)) &&
                "rgba(39, 198, 169, 0.50)",
            }}
          >
            {isEnglishEcosystem ? "Events" : "משימות"}
            <Circle color={isEnglishEcosystem ? "#F7976B" : "#B0BFDE"} />
          </Tab>
          <Tab
            onClick={() => {
              setActiveTab(Tabs.All);
            }}
            style={{
              background: activeTab === Tabs.All && "rgba(39, 198, 169, 0.50)",
            }}
          >
            {isEnglishEcosystem ? "All" : "הכל"}
          </Tab>
        </TabsContainer>
        <TabsContainer>
          {/*TODO: Active tabs  */}
          {zone?.toLowerCase?.() === EMERGENCY_ZONE &&
            EMERGENCY_TABS.map((tab) => (
              <Tab
                onClick={() => {
                  setActiveTab(tab.tabName);
                }}
                style={{
                  fontSize: activeTab === tab.tabName && "20px",
                  width: activeTab === tab.tabName && "120px",
                  height: activeTab === tab.tabName && "35px",
                }}
                backgroundColor={tab.backgroundColor}
                color={tab.color}
              >
                {tab.title}
                {tab.icon ? tab.icon : <Circle color={tab.iconColor} />}
              </Tab>
            ))}
        </TabsContainer>
        <MapContainer>
          <Map
            centerCity={ecosystem?.title}
            disableClick
            height={"425px"}
            borderRadius={"20px"}
            events={mapEvents}
          />
          {selectedEvent && (
            <EventInformationContainer>
              <EventTitle>
                {selectedEvent.title ?? selectedEvent.message}
                <CloseIconWrapper
                  onClick={() => {
                    setSelectedEvent(null);
                  }}
                >
                  <CloseIcon />
                </CloseIconWrapper>
              </EventTitle>
              {selectedEvent.image && (
                <EventImage src={getPhotoSrc(selectedEvent.image)} />
              )}
              <EventDateWrapper>
                <DateFormatComponent
                  startDate={selectedEvent.start_date}
                  endDate={selectedEvent.end_date}
                />
                {selectedEvent.start_time && selectedEvent.end_time && (
                  <div>{`${selectedEvent.start_time}-${selectedEvent.end_time}`}</div>
                )}
              </EventDateWrapper>
              <VisitBtn
                onClick={() => {
                  if (selectedEvent.message) {
                    setShowOverlay(true);
                    setOverlayProps({ id: selectedEvent._id });
                    return;
                  }
                  const navUrl = selectedEvent.dataType
                    ? `/activities/${zone}/card-details/${selectedEvent?.dataType}/${selectedEvent._id}/navBack`
                    : selectedEvent?.url ?? "";
                  navigate(navUrl);
                }}
              >
                Visit
              </VisitBtn>
            </EventInformationContainer>
          )}
        </MapContainer>
      </Container>
    </Wrapper>
  );
}

export default ZoneMap;
