import React, { useState } from "react";
import { Box, Dialog, styled as MuiStyled } from "@mui/material";
import VTTabs from "./components/VTTabs";
import { ABSTRACT_EMERGENCY_POPUP_TABS } from "./constants";
import { Tabs } from "./ZoneMap";
import { IoClose } from "react-icons/io5";
import Map from "../../../../kit/Map/Map";
import LocationDetails from "./components/LocationDetails";

const Modal = MuiStyled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: "20px",
    background: "#FAFFFA",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    minWidth: "359px",
    minHeight: "33px",
    overflowX: "hidden",
    padding: "40px 10px 20px 10px",
    position: "relative",
  },
}));

export const TEL_AVIV_LOCATION = {
  lng: 31.2323,
  lat: 32.51651,
};

const PopupZoneMap = ({ isOpen, onClose }) => {
  const [activeTabs, setActiveTabs] = useState([Tabs.All]);
  const [showLocationDetails, setShowLocationDetails] = useState(false);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <VTTabs
        tabsGroup={ABSTRACT_EMERGENCY_POPUP_TABS}
        activeTabs={activeTabs}
        setActiveTabs={setActiveTabs}
        width={"100%"}
      />
      <Box
        onClick={onClose}
        sx={{ position: "absolute", top: 5, left: 5, cursor: "pointer" }}
      >
        <IoClose size={30} />
      </Box>
      <Map
        placesType="hospital"
        initialSelectedLocation={TEL_AVIV_LOCATION}
        onMarkerClick={(loc) => setShowLocationDetails(loc)}
        disableClick
        height={"362px"}
        minWidth={"330px"}
        borderRadius={"20px"}
      />
      {showLocationDetails && (
        <LocationDetails
          location={{
            title: showLocationDetails?.name,
          }}
          reset={() => {
            setShowLocationDetails(false);
          }}
        />
      )}
    </Modal>
  );
};

export default PopupZoneMap;
