import React, { useContext, useEffect, useState } from "react";
import OverlayCard from "../components/OverlayCard";
import OverlayInput from "../components/OverlayInput";
import LocationIcon from "../../../../assets/icons/LocationIcon";
import MicrophoneIcon from "../../../../assets/icons/MicrophoneIcon";
import SendButton from "../components/SendButton";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import OverlayInputs from "../components/OverlayInputs";
import { VoiceContext } from "../components/Voice";
import useReadUserLocation from "../../../../customHooks/useReadUserLocation";
import { useAudioRecorder } from "react-audio-voice-recorder";
import Map from "../../../../kit/Map/Map";
import { fetchLocationName } from "../../../../kit/Map/MapUtils";
import { HiArrowLeft } from "react-icons/hi";
import useStopBodyScroll from "../../../../customHooks/useStopBodyScroll";
import StyledButton from "../../components/StyledButton";
import { hospitalBusIconV2 } from "../../../Auth/landingPage/images";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import {
  EMERGENCY_THEMES,
  EmergencyOverlaysContext,
} from "../EmergencyOverlaysContext";
import MuiButton from "../../../../kit/Button/MuiButton";
import useSound from "../../../../customHooks/useSound";
import completeSound from "../../../../assets/sounds/complete.mp3";
import { HiChevronLeft } from "react-icons/hi2";

import { DoubleBorder } from "../../../Common/Styled";
const Modal = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: "20px",
    background: "#fafffa",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    padding: 10,
    minWidth: "359px",
    overflowX: "hidden",
    border: "3px solid #EF7300",
    position: "relative",
  },
}));

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.6
  ); /* Grayed-out background color with some transparency */
  z-index: 99;
`;

const Title = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  color: ${(props) => props.color ?? "var(--preview-txt, #105F84)"};
  font-family: "NotoSans-500";
  font-size: ${(props) => props.fontSize ?? "24px"};
`;
const CardsWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin: 0;
  width: 100%;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  gap: 15px;
  margin-bottom: 15px;
`;
const Cancel = styled.div`
  text-align: center;
  cursor: pointer;
  color: #105f84;
  font-family: "NotoSans-600";
  font-size: 18px;
  margin-right: 20px;
  background: #e4fae6;
  border-radius: 10px;
  padding: 5px 15px;
  box-shadow: 5px 2px 5px 0px #00000040;
`;
const InformationContainer = styled.div`
  margin: auto;
  padding: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
`;
const Information = styled.p`
  margin: 0;
  color: var(--preview-txt, #58656d);
  text-align: center;
  font-family: "NotoSans-600";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ArrowWrapper = styled.div`
  cursor: pointer;
  top: 15px;
  left: 10px;
  position: absolute;
`;
const Text = styled.p`
  margin: 0;
  color: #ffffff;
  font-family: "NotoSans-600";
  font-size: 19.09px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const OrganizationOverlay = ({
  overlay,
  setOverlay,
  isReadyToSend,
  action,
}) => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, setValue } = useForm();
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [searchUserLocation, setSearchUserLocation] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [playSound] = useSound(completeSound);
  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
    recordingTime,
  } = useAudioRecorder();
  const { listening, StopRecord } = useContext(VoiceContext);
  const handleCloseClick = () => {
    if (showMap) {
      setShowMap(false);
      return;
    }
    setOverlay(null);
    action("");
    if (isRecording) {
      stopRecording();
    }
    if (listening) {
      StopRecord?.();
    }
  };
  const { userLocation } = useReadUserLocation();
  const [locationBgColor, setLocationBgColor] = useState();
  const { setShowOverlay, setTheme } = useContext(EmergencyOverlaysContext);
  const handleFormSubmit = async (data) => {
    if (listening) {
      StopRecord?.();
    }
    playSound();
    await action({
      ...data,
      recording: {
        blob:
          recordingDuration && recordingDuration != "0"
            ? recordingBlob
            : undefined,
        duration: recordingDuration,
      },
      selectedLocation: { loc: searchUserLocation, name: locationName },
      phone: phoneNumber,
    });
    setTheme(EMERGENCY_THEMES.organization);
    setTimeout(() => {
      setShowOverlay(false);
    }, 200);
  };

  const endRecord = () => {
    setRecordingDuration(recordingTime);
    stopRecording();
  };

  useEffect(() => {
    if (recordingTime >= 30) {
      endRecord();
    }
  }, [recordingTime]);

  useStopBodyScroll(overlay);
  useEffect(() => {
    if (!userLocation) {
      return;
    }
    setSearchUserLocation(userLocation);
    fetchLocationName(userLocation).then((name) => {
      setLocationName(name);
    });
  }, [userLocation]);

  const micOnClick = () => {
    if (isRecording) {
      endRecord();
      return;
    }
    startRecording();
  };

  const renderMessageInput = () => (
    <OverlayInput
      register={register}
      setValue={setValue}
      isReadyToSend={isReadyToSend}
      isOrganization={true}
      inputHeight={"80px"}
      title={"Message\u00A0הודעה"}
      showBottomMic={false}
      isVoiceRecording={isRecording}
      width={"100%"}
      margin={"auto"}
      showMicrophone
    />
  );

  const renderLocationRow = () => (
    <div
      style={{
        display: "flex",
        gap: "15px",
        width: "98%",
        margin: "10px auto 10PX auto",
      }}
    >
      <div style={{ flex: 1 }}>
        <StyledButton
          width={"100%"}
          height={"50px"}
          icon={
            <LocationIcon
              color={"rgba(12, 95, 135, 1)"}
              width={35}
              height={39}
            />
          }
          linkTitle={"Map בחרו מיקום"}
          linkTitleFontSize={"14px"}
          linkTitleColor={"rgba(88, 101, 109, 1)"}
          wrapperBackground={"rgba(56, 161, 188, 0.1)"}
          wrapperMargin={0}
          onClick={() => setShowMap(true)}
        />
      </div>
      <div style={{ flex: 1 }}>
        <StyledButton
          width={"100%"}
          height={"50px"}
          icon={
            <LocationIcon
              color={
                locationBgColor
                  ? "rgba(68, 109, 71, 1)"
                  : "rgba(12, 95, 135, 1)"
              }
              width={35}
              height={39}
            />
          }
          linkTitle={"שליחת מיקום נוכחי"}
          linkTitleFontSize={"14px"}
          linkTitleColor={"rgba(88, 101, 109, 1)"}
          wrapperBackground={
            locationBgColor
              ? "rgba(218, 243, 220, 1)"
              : "rgba(56, 161, 188, 0.1)"
          }
          wrapperMargin={0}
          onClick={() => {
            setShowMap(true);
            setLocationBgColor("sel");
          }}
        />
      </div>
    </div>
  );

  const renderContent = () => (
    <>
      <ArrowWrapper onClick={handleCloseClick}>
        <HiChevronLeft color={"#105F84"} fontSize={30} />
      </ArrowWrapper>
      <Title>
        <img width={"42"} height={"42"} src={hospitalBusIconV2} alt={"help"} />
        <div style={{ marginLeft: "15px" }}>{"ארגוני חירום והצלה"}</div>
      </Title>
      <div
        style={{
          width: "100%",
          margin: "10px 0",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <OverlayCard
          showShakeAnimation
          Icon={LocationIcon}
          iconWrapperBgColor={"#DAF3DCB2"}
          iconWrapperBorder={"1px solid #EF7300"}
          iconWrapperHeight={"43px"}
          titleFontSize={"20px"}
          titleFontWeight={"500"}
          iconSize={"30px"}
          IconColor={"#446D47"}
          title={"Location הוספת מיקום"}
          isReadyToSend={isReadyToSend}
          wrapperBgColor={
            locationBgColor ? "rgba(218, 243, 220, 1)" : "#38A1BC1A"
          }
          onClick={() => {
            setShowMap(true);
            setLocationBgColor("sel");
          }}
          height={"51px"}
        />
        <OverlayCard
          showShakeAnimation
          Icon={MicrophoneIcon}
          iconWrapperBgColor={"#DAF3DCB2"}
          iconWrapperBorder={"1px solid #EF7300"}
          iconWrapperHeight={"43px"}
          titleFontSize={"20px"}
          titleFontWeight={"500"}
          iconSize={"25px"}
          IconColor={isReadyToSend ? "#7FB383" : undefined}
          iconText={"Record"}
          counter={
            isRecording
              ? recordingTime
              : recordingDuration && recordingDuration !== 0
              ? recordingDuration
              : undefined
          }
          showDelete={!!(recordingDuration && recordingDuration !== 0)}
          onDeleteClick={() => {
            setRecordingDuration(0);
            stopRecording();
          }}
          title={"הקליטו הודעתכם"}
          isVoiceComponent
          isReadyToSend={isReadyToSend}
          wrapperBgColor={
            recordingTime || (recordingDuration && recordingDuration !== 0)
              ? "#EBDBDD"
              : "#38A1BC1A"
          }
          onClick={micOnClick}
          height={"51px"}
        />
      </div>
      <div style={{ width: "100%", marginBottom: "15px" }}>
        {renderMessageInput()}
      </div>
      <ButtonWrapper>
        <DoubleBorder>
          <SendButton
            color={"#FFFFF"}
            backgroundColor={"#EF7300"}
            border={"0"}
            padding={"25px 5px"}
            borderRadius={"5px"}
            width={"100%"}
            marginTop={0}
            isReadyToSend={isReadyToSend}
            onSubmit={handleSubmit(handleFormSubmit)}
            reverseOrder
            text={
              <>
                <Text>שליחת הודעה</Text>
                {"\u00A0 \u00A0 \u00A0 \u00A0 \u00A0"}
                <Text>Send</Text>
              </>
            }
          />
        </DoubleBorder>
        <MuiButton
          sx={{
            color: "#105F84",
            fontSize: 18,
            fontFamily: "NotoSans-600",
            border: 0,
            borderRadius: "10px",
            width: 118,
            height: 30,
            backgroundColor: "#EDF9ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow: "5px 2px 5px 0px rgba(0, 0, 0, 0.25)",
            "&:hover": {
              border: 0,
            },
          }}
          onClick={handleCloseClick}
        >
          <div>Cancel</div>
          <div>בטל</div>
        </MuiButton>
      </ButtonWrapper>
      <CardsWrapper>
        <OverlayInputs
          phoneNumber={phoneNumber}
          title={"פרטים אישיים"}
          register={register}
          setPhoneNumber={setPhoneNumber}
          fullName={watch("fullName")}
          width={"100%"}
          margin={"auto"}
          hasSubTitle
        />
      </CardsWrapper>
    </>
  );

  return (
    <>
      <BackgroundOverlay aria-hidden onClick={handleCloseClick} />
      <Modal onClose={handleCloseClick} open>
        {showMap ? (
          <>
            <HiArrowLeft
              size={"1.5em"}
              style={{
                color: "#4B778D",
                position: "absolute",
                top: 15,
                left: 15,
              }}
              onClick={() => setShowMap(false)}
            />
            <div style={{ width: "100%", marginTop: "35px" }}>
              <Map
                disableClick={false}
                height={"60vh"}
                initialSelectedLocation={userLocation ?? searchUserLocation}
                showSearch={true}
                selectedLocationName={locationName}
                onLocationSelect={(loc) => {
                  setSearchUserLocation(loc);
                  fetchLocationName(loc).then((name) => {
                    setLocationName(name);
                  });
                }}
              />
            </div>
            <InformationContainer>
              <Information>{locationName}</Information>
            </InformationContainer>
          </>
        ) : (
          renderContent()
        )}
      </Modal>
    </>
  );
};

export default OrganizationOverlay;
