import React from "react";

const InteractiveMapPoint = ({ onClick, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={36}
      fill="none"
      viewBox="0 0 18 36"
      onClick={onClick}
      style={style}
    >
      <path
        fill="#BE3535"
        d="M9.477 0c.502.115 1.01.204 1.505.348 3.299.958 5.76 3.949 6.179 7.49.572 4.842-2.59 9.239-7.2 10.01-.238.04-.48.062-.757.098V34.39c0 .126.008.254-.008.378-.042.329-.28.55-.58.55-.298 0-.538-.223-.578-.55-.015-.124-.007-.252-.007-.378V18.02c-.773-.195-1.544-.305-2.254-.585-3.243-1.28-5.203-3.707-5.692-7.298C-.56 5.388 2.541 1.104 6.921.166c.278-.06.557-.11.834-.166h1.722Zm-1.45 6.594c.003-1.327-1.007-2.39-2.274-2.392C4.488 4.2 3.455 5.264 3.451 6.575 3.448 7.887 4.48 8.969 5.734 8.97c1.267.002 2.292-1.06 2.295-2.377h-.001Z"
      />
    </svg>
  );
};

export default InteractiveMapPoint;
