import React from "react";
import studentMapImage from "../mock/emergency-student-map.png";
import mockLocationImage from "../images/mock-school-location-image.png";
import InteractiveLandingMap from "./InteractiveLandingMap";

const SchoolInteractiveLandingMap = () => {
  return (
    <InteractiveLandingMap
      mapImage={studentMapImage}
      pointPosition={{ top: "70px", right: "130px" }}
      locationDetails={{
        title: "בית חולים איכילוב",
        image: mockLocationImage,
        wazeUrl: "https://waze.com/ul?q=ויצמן 6, תל אביב-יפו&navigate=yes",
      }}
      width="346px"
      height="270px"
    />
  );
};

export default SchoolInteractiveLandingMap;
