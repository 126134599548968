import { useState, useEffect, useMemo } from "react";
import useFetchActivities from "../../../../../customHooks/useFetchActivities";
import useFetchEvents from "../../../../../customHooks/useFetchEvents";
import useFetchTasks, {
  TaskZone,
} from "../../../../../customHooks/useFetchTasks";
import useFetchEmergencyEvents, {
  MainEmergencyZone,
} from "../../../../../customHooks/useFetchEmergencyEvents";
import { useQuery } from "@tanstack/react-query";
import { getEmergencies } from "../../../Zone/Activities/Create/api";
import { MarkerIcon } from "../../../../../kit/Map/Map";
import { DataType } from "../../../Zone/Common/CardDetailsPage/CardDetailsPage";

const EMERGENCY_ZONE = "emergency";

const communitiesMock = [
  {
    lattiude: 32.090842,
    longitude: 34.775687,
    title: "Imaot Ramat Aviv",
    url: "/public/activities/together/tel-aviv/imaot-ramat-aviv",
  },
  {
    lattiude: 32.075262,
    longitude: 34.789456,
    title: "Imaot Yad Elihao",
    url: "/public/activities/together/tel-aviv/imaot-yad-elihao",
  },
  {
    lattiude: 32.07411,
    longitude: 34.772336,
    title: "Noga",
    url: "/activities/together/noga",
  },
];

const useMapEvents = ({
  zone,
  activeTab,
  setSelectedEvent,
  area,
  isEnglishEcosystem,
  Tabs,
  shouldFetch = true,
}) => {
  const { fetchedActivities } = useFetchActivities(
    area ?? zone ?? TaskZone.TelAvivCity,
    { enabled: shouldFetch }
  );
  const {
    emergency1Events,
    emergency2Events,
    emergency3Events,
    emergency4Events,
    emergency5Events,
    emergency5CEvents,
    emergency6Events,
    emergency7Events,
  } = useFetchEmergencyEvents(MainEmergencyZone.Emergenc, {
    enabled: shouldFetch,
  });
  const { fetchedEvents } = useFetchEvents(
    area ?? zone ?? TaskZone.TelAvivCity,
    { enabled: shouldFetch }
  );
  const { fetchedTasks } = useFetchTasks(area ?? zone ?? TaskZone.TelAvivCity);
  const { data: fetchedEmergencies } = useQuery({
    queryKey: ["emergencies", zone],
    queryFn: () => getEmergencies(),
    enabled: shouldFetch,
  });
  const activities = useMemo(
    () =>
      fetchedActivities?.map((act) => {
        const actMarker = {
          ...act,
          markerIcon: MarkerIcon.Green,
          dataType: DataType.Activity,
        };
        return (
          {
            ...actMarker,
            onClick: () => setSelectedEvent(actMarker),
          } ?? []
        );
      }),
    [fetchedActivities]
  );
  const tasks = useMemo(
    () =>
      fetchedTasks?.map((task) => {
        const taskMarker = {
          ...task,
          markerIcon: MarkerIcon.Gray,
          dataType: DataType.Task,
        };
        return (
          {
            ...taskMarker,
            onClick: () => setSelectedEvent(taskMarker),
          } ?? []
        );
      }),
    [fetchedTasks]
  );
  const events = useMemo(() => {
    if (zone?.toLowerCase?.() === EMERGENCY_ZONE) {
      if (
        !emergency1Events ||
        !emergency2Events ||
        !emergency3Events ||
        !emergency4Events ||
        !emergency5Events ||
        !emergency5CEvents ||
        !emergency6Events ||
        !emergency7Events
      ) {
        return [];
      }
      return [
        ...emergency1Events,
        ...emergency2Events,
        ...emergency3Events,
        ...emergency4Events,
        ...emergency5Events,
        ...emergency5CEvents,
        ...emergency6Events,
        ...emergency7Events,
      ]?.map((event) => {
        const eventMarker = {
          ...event,
          markerIcon: MarkerIcon.Orange,
          dataType: DataType.Event,
        };
        return (
          {
            ...eventMarker,
            onClick: () => setSelectedEvent(eventMarker),
          } ?? []
        );
      });
    }
    return fetchedEvents?.map((event) => {
      const eventMarker = {
        ...event,
        markerIcon: isEnglishEcosystem ? MarkerIcon.Orange : MarkerIcon.Green,
        dataType: DataType.Event,
      };
      return (
        {
          ...eventMarker,
          onClick: () => setSelectedEvent(eventMarker),
        } ?? []
      );
    });
  }, [
    emergency1Events,
    emergency2Events,
    emergency3Events,
    emergency4Events,
    emergency5CEvents,
    emergency5Events,
    emergency6Events,
    emergency7Events,
    fetchedEvents,
    zone,
    isEnglishEcosystem,
  ]);
  const emergencies = useMemo(
    () =>
      fetchedEmergencies?.data?.emergencies?.map?.((em) => {
        const emergencyMarker = {
          ...em,
          markerIcon: MarkerIcon.Orange,
          lattiude: em.latitude,
        };
        return (
          {
            ...emergencyMarker,
            onClick: () => setSelectedEvent(emergencyMarker),
          } ?? []
        );
      }),
    [fetchedEmergencies]
  );
  const communities = useMemo(
    () =>
      communitiesMock?.map((community) => {
        const communityMarker = {
          ...community,
          markerIcon: MarkerIcon.Blue,
        };
        return (
          {
            ...communityMarker,
            onClick: () => setSelectedEvent(communityMarker),
          } ?? []
        );
      }),
    []
  );

  const mapEvents = useMemo(() => {
    if (activeTab === Tabs.All || activeTab === Tabs.VT_All) {
      return [
        ...(activities ?? []),
        ...(events ?? []),
        ...(tasks ?? []),
        ...(zone === EMERGENCY_ZONE ? emergencies : []),
        ...communities,
      ];
    } else if (activeTab === Tabs.Tasks) {
      return tasks;
    } else if (
      activeTab === Tabs.Events ||
      activeTab === Tabs.ActivitiesEvents
    ) {
      return [...activities, ...events];
    } else if (activeTab === Tabs.Communities) {
      return communities;
    } else if (activeTab === Tabs.Emergency) {
      return emergencies;
    } else {
      return [];
    }
  }, [activeTab, activities, events, tasks, emergencies, communities, zone]);

  return mapEvents;
};

export default useMapEvents;
