import React, { useState, useContext } from "react";
import { FlexColumn, FlexRow } from "../../../Common/Styled";
import MuiButton from "../../../../kit/Button/MuiButton";
import { useNavigate, useParams } from "react-router-dom";
import matnasMapImage from "../mock/emergency-matnas-map.png";
import map from "../mock/emergency-map.png";
import styled from "styled-components";
import {
  EmergencyLogoV2,
  TogetherIconV2,
  UnitedHatzalahIcon,
} from "../../../../assets/icons/Logo";
import { UserIcon } from "../../../../assets/icons";
import BellIcon from "../../../../assets/icons/Activities/LandingPage/BellIcon";
import { LoginModal } from "../../../../kit/Modal";
import Header from "../../../Common/Layout/Header";
import { REGION } from "../../../Common/Layout/Main/constants";
import useCurrentUser from "../../../../customHooks/useCurrentUser";
import HeaderActions from "../HeaderActions/HeaderActions";
import { EmergencyOverlaysContext } from "../../../Emergency/Contacts/EmergencyOverlaysContext";
import {
  hospitalBusIconV2,
  jewishAgencyImage,
  matnasimImage,
  needHelpIconV2,
} from "../images";
import useRegion from "../../../../customHooks/useRegion";
import LogoWithText from "../../../../kit/Icon/IconWithText";
import { Box } from "@mui/material";
import AbstractLandingFooter from "../component/AbstractLandingFooter";
import SchoolInteractiveLandingMap from "../InteractiveLandingMap/SchoolInteractiveLandingMap";
import EmergencyInteractiveLandingMap from "../InteractiveLandingMap/EmergencyInteractiveLandingMap";
import PopupZoneMap from "../../../Ecosystem/Public/Map/PopupZoneMap";

const Wrapper = styled.div`
  background-color: #e5eeff;
  min-height: calc(100vh - 30px);
  padding-top: 29px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const JewishHeaderText = styled.div`
  color: #316788;
  text-align: center;
  font-family: "NotoSans-500";
  font-size: 22px;
  text-decoration: underline;
`;
const HeaderText = styled.div`
  color: #316788;
  font-size: ${(props) => props.fontSize ?? "30px"};
  font-weight: 600;
  line-height: 30px;
  font-family: "NotoSans-600";
  margin-bottom: ${(props) => props.marginBottom ?? "35px"};
  margin-top: ${(props) => props.marginTop ?? "auto"};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;
const Image = styled.img`
  width: 90%;
  cursor: pointer;
  margin-bottom: 20px;
`;

const emergencyButtonsStyle = {
  color: "#316788",
  fontSize: 11,
  fontWeight: 600,
  fontFamily: "NotoSans-600",
  border: "3px solid #003F5D",
  borderRadius: "10px",
  width: 159.25,
  height: 102,
  textTransform: "none",
  backgroundColor: "#003F5D1A",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-end",
  position: "relative",
  padding: "0 5px",
  lineHeight: "normal",
  "&:hover": {
    border: "2px solid #003F5D",
  },
};

const buttonStyles = {
  color: "#003F5D",
  fontSize: 15,
  fontWeight: 600,
  fontFamily: "Assistant",
  backgroundColor: "transparent",
  border: "1px solid #003F5D",
  borderRadius: "32.5px",
  width: 109,
  height: 27,
  textTransform: "none",
  padding: "5px 0",
  "&:hover": {
    backgroundColor: "#CFDBF5",
    border: "2px solid #003F5D",
  },
  "& .MuiButton-icon": {
    marginRight: "5px",
  },
};

const SOSButton = styled.div`
  background-color: ${(props) => props.backgroundColor || "#E81616"};
  border-radius: 10px;
  padding: 3px;
  cursor: pointer;
`;

export const EMERGENCY_LANDING_ROUTE =
  process.env.REACT_APP_REGION === "abstract_emergency" ? "/" : "/emergency";

const AbstractEmergencyLanding = () => {
  const { isVisitor, isAuthenticated } = useCurrentUser();
  const {
    setShowOverlay,
    setOverlayProps,
    setReassurance,
    setShowEmergencyMap,
  } = useContext(EmergencyOverlaysContext);
  const navigate = useNavigate();
  const {
    isJewishEmergency: isJewishEmergencyRegion,
    isAbstractEmergency,
    isSosV2,
  } = useRegion();
  const { id } = useParams();

  const isJewishEmergency = isJewishEmergencyRegion || id === "jfk";
  const isCity =
    id === "matnas" ||
    window.location.hostname === "www.volunteer-ecosystem.co.il" ||
    window.location.hostname === "city-ecosystem.com";
  const isStudent =
    id === "students" || window.location.hostname === "student-ecosystem.com";
  const isSchool = window.location.hostname === "school-ecosystem.com";

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showMap, setShowMap] = useState(false);

  return (
    <Wrapper>
      {isVisitor || isAuthenticated ? (
        <HeaderActions />
      ) : isJewishEmergency ? (
        <img
          style={{
            width: "215px",
            height: "53.33px",
            marginRight: "auto",
            paddingLeft: "40px",
          }}
          src={jewishAgencyImage}
          alt={"jewishAgencyImage"}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: isAbstractEmergency ? "start" : "center",
            gap: "20px",
            width: "100%",
            padding: "15px 33px 35px 33px",
            boxSizing: "border-box",
            flexDirection: isAbstractEmergency ? "column" : "row",
          }}
        >
          <MuiButton
            variant="outlined"
            onClick={() => navigate(`/login/${REGION.Abstract_Emergency}`)}
            sx={{
              ...buttonStyles,
              backgroundColor:
                isSchool || isStudent ? "#F6905880" : "transparent",
              width: isSchool ? 118 : 109,
            }}
            startIcon={<UserIcon />}
          >
            {isSchool ? "private zone" : "לאיזור אישי"}
          </MuiButton>
          {(isCity || isStudent) && (
            <img
              style={{ width: "154px", height: "41.35px" }}
              src={matnasimImage}
              alt="matnasimImage"
            />
          )}
          {isAbstractEmergency && !isStudent && !isCity && !isSchool && (
            <EmergencyLogoV2 width={120} height={50} />
          )}
        </Box>
      )}
      <Header
        Icon={
          isCity || isStudent
            ? () => (
                <LogoWithText
                  Icon={TogetherIconV2}
                  top={isStudent || isCity ? "48px" : "83px"}
                  left={"-4px"}
                  title={"במצבי חירום"}
                  iconWidth="300"
                  iconHeight={isStudent || isCity ? "70" : "150"}
                  titleStyles={`
                font-size: 28px;
                color: #EF7300;
                font-weight: 700;
              `}
                />
              )
            : () =>
                isAbstractEmergency && !isSchool ? (
                  <UnitedHatzalahIcon />
                ) : (
                  <EmergencyLogoV2
                    width={isSchool ? 220 : 326}
                    height={isSchool ? 70 : 105}
                  />
                )
        }
        iconHeight={155}
        iconWidth={340}
        padding={
          isSchool
            ? "0 0 5px 0"
            : isAbstractEmergency
            ? "0 0 25px 0"
            : "0 0 85px 0"
        }
        position={"static"}
        navbarTopBtnFill={"rgb(0, 63, 93)"}
        navbarBottomBtnFill={"rgb(49, 103, 136)"}
        showNavbar={!isVisitor && !isAuthenticated}
      />
      {isSchool && (
        <>
          <img
            src={jewishAgencyImage}
            alt="jewishAgencyImage"
            style={{ width: "341px", height: "84.58px", marginBottom: "30px" }}
          />
          <MuiButton
            sx={{
              ...emergencyButtonsStyle,
              width: 346,
              paddingBottom: "5px",
              color: "#ffffff",
              flexDirection: "row",
              justifyContent: "space-between",
              fontSize: 23,
              marginBottom: "10px",

              backgroundColor: "#EF7300",
              border: " thick double #ffffff",

              "&:hover": {
                backgroundColor: "#EF7300",

                borderColor: "#EF7300",
              },
            }}
            onClick={() => {
              setShowEmergencyMap(false);
              setShowOverlay(true);
              setOverlayProps({
                isOrganization: true,
              });
            }}
          >
            <img
              style={{
                width: "78px",
                height: "78px",
              }}
              src={hospitalBusIconV2}
              alt="hospitalBusIcon"
            />
            <flexRow>
              <FlexRow gap={"5px"} alignItems={"center"}>
                <div>Organizations</div>
                <BellIcon width={"25.4"} height={"28.2"} color={"#851B1B"} />
              </FlexRow>
              <div>ארגוני חירום והצלה</div>
            </flexRow>
          </MuiButton>
        </>
      )}
      {isJewishEmergency || isSchool ? (
        <JewishHeaderText>You are not alone!</JewishHeaderText>
      ) : isCity || isStudent ? (
        <HeaderText marginBottom={0} fontSize={"25px"}>
          <div>המשפחה, השכנים והחברים</div>
          <div> תמיד בשבילך ולצידך</div>
        </HeaderText>
      ) : (
        <FlexColumn alignItems={"center"} sx={{ width: "100%" }}>
          <HeaderText>
            <div>כולנו ביחד במצבי חירום</div>
          </HeaderText>
        </FlexColumn>
      )}
      {isCity || isStudent || isSchool ? (
        <FlexColumn
          sx={{
            marginTop: "30px",
            marginBottom: "25px",
            width: "100%",
            boxSizing: "border-box",
            padding: "0 27px",
            justifyContent: "center",
            alignItems: "center",
            gap: "22px",
          }}
        >
          <SOSButton
            onClick={() => {
              setShowEmergencyMap(false);
              setShowOverlay(true);
              setOverlayProps({
                isNeedHelp: true,
              });
            }}
            backgroundColor={"#2F95C5"}
          >
            <MuiButton
              sx={{
                color: "#FFF",
                fontSize: 23,
                fontWeight: 600,
                fontFamily: "Assistant",
                border: "3px solid #FFF",
                borderRadius: "10px",
                width: 343,
                height: 106.19,
                textTransform: "none",
                backgroundColor: isSchool ? "#0270A5" : "#2F95C5",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                position: "relative",
                lineHeight: "normal",
                boxSizing: "border-box",
                "&:hover": {
                  border: "3px solid #FFF",
                  backgroundColor: isSchool ? "#0270A5" : "#2F95C5",
                },
              }}
            >
              <img
                style={{
                  width: "72px",
                  height: "72px",
                }}
                src={needHelpIconV2}
                alt="helpNeededIcon"
              />
              <FlexColumn>
                <div>בקשת סיוע אישית</div>
                <div>Some Help is Needed</div>
              </FlexColumn>
            </MuiButton>
          </SOSButton>
          {isCity && (
            <>
              <HeaderText marginBottom={0} fontSize={"25px"}>
                כולנו ביחד במצבי מצוקה
              </HeaderText>
              <SOSButton
                onClick={() => {
                  setShowEmergencyMap(false);
                  setShowOverlay(true);
                  setReassurance(false);
                  setOverlayProps({
                    isSOS: true,
                    isBlue: true,
                  });
                }}
              >
                <MuiButton
                  sx={{
                    color: "#FFF",
                    fontSize: 23,
                    fontWeight: 600,
                    fontFamily: "Assistant",
                    border: "3px solid #FFF",
                    borderRadius: "10px",
                    width: 343,
                    height: 106.19,
                    textTransform: "none",
                    backgroundColor: "#F55050",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    position: "relative",
                    lineHeight: "normal",
                    boxSizing: "border-box",
                    "&:hover": {
                      border: "3px solid #FFF",
                      backgroundColor: "#a70000",
                    },
                  }}
                >
                  <BellIcon width={"70"} height={"75"} color={"#FFF"} />
                  S.O.S - לחצו במצוקה
                </MuiButton>
              </SOSButton>
            </>
          )}
        </FlexColumn>
      ) : (
        <>
          <FlexColumn
            sx={{
              marginTop: "30px",
              marginBottom: isAbstractEmergency
                ? "0"
                : isSosV2
                ? "50px"
                : "25px",
              width: "100%",
              boxSizing: "border-box",
              padding: "0 27px",
              justifyContent: "center",
              alignItems: "center",
              gap: "22px",
            }}
          >
            <SOSButton
              onClick={() => {
                setShowEmergencyMap(false);
                setShowOverlay(true);
                setReassurance(false);
                setOverlayProps({
                  isSOS: true,
                  isBlue: true,
                });
              }}
            >
              <MuiButton
                sx={{
                  color: "#FFF",
                  fontSize: 23,
                  fontWeight: 600,
                  fontFamily: "Assistant",
                  border: "3px solid #FFF",
                  borderRadius: "10px",
                  width: 343,
                  height: 106.19,
                  textTransform: "none",
                  backgroundColor: "#E81616",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-around",
                  position: "relative",
                  lineHeight: "normal",
                  boxSizing: "border-box",
                  "&:hover": {
                    border: "3px solid #FFF",
                    backgroundColor: "#a70000",
                  },
                }}
              >
                <BellIcon width={"38"} height={"42"} color={"#FFF"} />
                S.O.S - לחצו מצוקה
              </MuiButton>
            </SOSButton>
            {!isSosV2 && (
              <FlexRow sx={{ gap: "19px", flex: 1, marginBottom: "50px" }}>
                <MuiButton
                  sx={{
                    ...emergencyButtonsStyle,
                    paddingBottom: "5px",
                    color: "#ffffff",

                    backgroundColor: "#EF7300",
                    border: " thick double #ffffff",

                    "&:hover": {
                      backgroundColor: "#EF7300",

                      borderColor: "#EF7300",
                    },
                  }}
                  onClick={() => {
                    setShowEmergencyMap(false);
                    setShowOverlay(true);
                    setOverlayProps({
                      isOrganization: true,
                    });
                  }}
                >
                  <img
                    style={{
                      width: "58.91px",
                      height: "58.91px",
                      position: "absolute",
                      top: 0,
                    }}
                    src={hospitalBusIconV2}
                    alt="hospitalBusIcon"
                  />
                  <div>ארגוני חירום והצלה</div>
                  <div>Organizations</div>
                </MuiButton>
                <MuiButton
                  sx={{
                    ...emergencyButtonsStyle,
                    paddingBottom: "5px",
                    backgroundColor: "#1e5f8c",
                    color: "#ffffff",
                    fontSize: "11px",
                    border: " thick double #ffffff",
                    "&:hover": {
                      backgroundColor: "#174a6a",
                      borderColor: "#ffffff",
                    },
                  }}
                  onClick={() => {
                    setShowEmergencyMap(false);
                    setShowOverlay(true);
                    setOverlayProps({
                      isNeedHelp: true,
                    });
                  }}
                >
                  <img
                    style={{
                      width: "45px",
                      height: "45px",
                      position: "absolute",
                      top: "5px",
                    }}
                    src={needHelpIconV2}
                    alt="helpNeededIcon"
                  />
                  <div>בקשת סיוע אישית</div>
                  <div>Some Help is Needed</div>
                </MuiButton>
              </FlexRow>
            )}
          </FlexColumn>
        </>
      )}
      {(isCity || isStudent || isSosV2) && !isSchool && (
        <Box
          sx={{
            fontSize: 24,
            fontFamily: "NotoSans-600",
            fontWeight: 600,
            color: "#316788",
            marginBottom: "25px",
            textDecoration: "underline",
          }}
        >
          מפת מוקדי סיוע והצלה
        </Box>
      )}
      {
        // isAbstractEmergency ? (
        //   <EmergencyInteractiveLandingMap />
        // ) :
        isStudent || isSchool ? (
          <SchoolInteractiveLandingMap />
        ) : (
          <Image
            onClick={() =>
              isAbstractEmergency
                ? setShowMap(true)
                : navigate(
                    `/activities/map/${REGION.Abstract_Emergency}/telAvivCity`
                  )
            }
            src={isAbstractEmergency ? map : matnasMapImage}
            alt="mapImage"
          />
        )
      }
      {isAbstractEmergency && <AbstractLandingFooter />}
      {showLoginModal && (
        <LoginModal
          handleClose={() => setShowLoginModal(false)}
          open={showLoginModal}
          onSubmit={() => navigate("/emergency-create-profile")}
          englishTitle={"PROFILE"}
          hebrewTitle={"פרופיל אישי"}
          contentTitle={"User - משמתמש קיים"}
          actionTitle={"Sign Up  / Edit - הרשמה למערכת"}
          submitHebrewText={"עדכון / יצירת פרופיל"}
          backgroundColor={"#E5EEFF"}
          contentBackgroundColor={"#95B5F24D"}
          actionBackgroundColor={"#BFD5FFCC"}
          inputNum={6}
          hasPhoneNumber
        />
      )}
      {showMap && (
        <PopupZoneMap isOpen={showMap} onClose={() => setShowMap(false)} />
      )}
    </Wrapper>
  );
};

export default AbstractEmergencyLanding;
